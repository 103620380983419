import React from "react";
import BotIcon from "../../assets/img/ai-assistant.png";
import CustomerIcon from "../../assets/img/customer.png";
import WidgetInfo from "./WidgetInfo";
import AiShoppingCarousel from "./AiShoppingCarousel";
import GraphicEqIcon from '@mui/icons-material/GraphicEq';
import StopCircleIcon from '@mui/icons-material/StopCircle';
import { useReadContext } from "../../common/context/ReadContext";
import { useDispatch } from "react-redux";
import { Tooltip } from '@mui/material';

import { aiUpdateAllMessageReadingStatus, aiUpdateMessageReadingStatus, setLastMessage } from "../../store/slices/shoppingAssistanceSlice";
import * as speechsdk from "microsoft-cognitiveservices-speech-sdk";
const AZURE_SPEECH_KEY = "1CEubzEkC3OqxQVRvU3kjGtzBXpLMUt8OE19N9RKxc1eDOlfGdJ7JQQJ99BAACYeBjFXJ3w3AAAYACOGqbud"; 
const AZURE_SPEECH_REGION = "eastus"; 


let audioElement = null; // Global audio element reference
let globalUpdateReadingStatus = null; // Stores update function
const handleRead = (isReadEnabled, selectedVoice, text, message = null, hasInput = false) => {
  if (!isReadEnabled || !selectedVoice) return;
  let paused = false; // Track if the speech synthesis was paused
  // Stop any ongoing speech synthesis
  window.speechSynthesis.cancel();

  // Create a new speech utterance
  const utterance = new SpeechSynthesisUtterance(text);
  utterance.rate = 0.9;
  utterance.voice = selectedVoice;
  const element = document.querySelector(`.AIresults-card`);

    // Remove custom inline styles
    if (element) {
        element.style.cssText = ""; // Clears all inline styles
        // Remove the specific class
        element.classList.remove("highlight-card");
    }
  // Attach the onend event
  utterance.onend = () => {
    if (!hasInput && message?.message?.packages?.length > 0) {
     // Use a shallow copy of the message object
     const updatedMessage = { ...message };
     updatedMessage.message = { ...message.message }; // Copy nested message object
     updatedMessage.message.packages = [...message.message.packages]; // Copy the packages array

     // Read only the first package
     const firstPackage = updatedMessage.message.packages[0].readOut;
     console.log("Reading first package:", firstPackage);

     // Mark the package as read by clearing the copied packages array
     updatedMessage.message.packages = [];
      // Recursively call handleRead to read the first package
      handleRead(isReadEnabled, selectedVoice, firstPackage, updatedMessage, false);
    } else {
      if (element) {
          element.style.cssText = ""; // Clears all inline styles
          // Remove the specific class
          element.classList.remove("highlight-card");
      }
      console.log("Finished reading all packages or no packages available.");
    }
  };

  // Log boundary events for debugging
  utterance.onboundary = (event) => {
    console.log("Boundary reached:", event.charIndex);
  };

  // Handle errors
  utterance.onerror = (event) => {
    console.error("Speech synthesis error:", event.error);
  };

   // Handle tab visibility changes
   const handleVisibilityChange = () => {
    if (document.visibilityState === "hidden") {
      console.log("Tab hidden: Pausing playback.");
      window.speechSynthesis.pause(); // Stop speech synthesis
      paused = true; // Mark as paused
    } else if (document.visibilityState === "visible" && paused) {
      console.log("Tab visible: Resuming playback.");
      // Resume playback by creating a new utterance for the remaining text
      window.speechSynthesis.resume(utterance);
      paused = true;
    }
  };
  // Attach the visibilitychange event
  document.addEventListener("visibilitychange", handleVisibilityChange);

  // Speak the utterance after a slight delay
  setTimeout(() => {
      window.speechSynthesis.speak(utterance);
  }, 100);
  utterance.onend = () => {
    console.log("Speech synthesis finished.");
    document.removeEventListener("visibilitychange", handleVisibilityChange);
  };
};

// export const handleVoiceOverForMessagesAndPackage = (
//   isReadEnabled,
//   selectedVoice,
//   messages = [],
//   firstPackage = null,
//   messageObj = null,
//   messageIndex = null,
//   dispatch,
//   setSliderCurrentIndex = null, 
//   setIsSliderReading = null,
//   subIndex = 0
// ) => {
//   if (!isReadEnabled || !selectedVoice) {
//     console.log("Speech synthesis is disabled or no voice selected.");
//     return;
//   }

//   // Ensure `messages` is always an array
//   if (typeof messages === "string") {
//     messages = [messages];
//   }
//   if (!Array.isArray(messages) || messages.length === 0) {
//     console.log("No messages to read.");
//     return;
//   }

//   // Stop any ongoing speech synthesis
//   window.speechSynthesis.cancel();

//   let currentIndex = 0;
//   let hasReadPackage = false;
//   let paused = false;

//   // Update messageObj state
//   const updatedMessageObj = {
//     ...messageObj,
//     message: {
//       ...messageObj?.message,
//       isReading: true,
//       subIndex,
//     },
//   };

//   console.log("subIndex:", subIndex);
//   dispatch(aiUpdateMessageReadingStatus({ updatedMessageObj, messageIndex }));

//   // Function to handle speech synthesis
//   const speakNext = (text, callback) => {
//     const utterance = new SpeechSynthesisUtterance(text);
//     utterance.voice = selectedVoice;
//     utterance.rate = 0.9;

//     utterance.onerror = (event) => {
//       console.error("Speech synthesis error:", event.error);
//       updateReadingStatus(false);
//     };

//     utterance.onend = () => {
//       console.log("Finished reading:", text);
//       if (callback) callback();
//     };

//     setTimeout(() => {
//       window.speechSynthesis.speak(utterance);
//     }, 100);
//   };

//   // Function to update reading status
//   const updateReadingStatus = (isReading) => {
//     const finalUpdatedMessageObj = {
//       ...messageObj,
//       message: {
//         ...messageObj?.message,
//         isReading,
//         subIndex,
//       },
//     };
//     dispatch(aiUpdateMessageReadingStatus({ updatedMessageObj: finalUpdatedMessageObj, messageIndex }));
//   };

//   const updateCardReadingStatus = (isReading,cardIndex) => {
//     const finalUpdatedMessageObj = {
//       ...messageObj,
//       message: {
//         ...messageObj?.message,
//         isCardReading:isReading,
//         currentReadingCardIndex:cardIndex,
//         subIndex,
//       },
//     };
//     dispatch(aiUpdateMessageReadingStatus({ updatedMessageObj: finalUpdatedMessageObj, messageIndex }));
//   };

//   // Handle tab visibility changes (only attach once)
//   const handleVisibilityChange = () => {
//     if (document.visibilityState === "hidden") {
//       console.log("Tab hidden: Pausing playback.");
//       window.speechSynthesis.pause();
//       paused = true;
//     } else if (document.visibilityState === "visible" && paused) {
//       console.log("Tab visible: Resuming playback.");
//       window.speechSynthesis.resume();
//       paused = false;
//     }
//   };

//   document.addEventListener("visibilitychange", handleVisibilityChange);

//   // Function to read messages and the package
//   const readMessagesAndPackage = () => {
//     if (currentIndex < messages.length) {
//       speakNext(messages[currentIndex], () => {
//         currentIndex += 1;
//         readMessagesAndPackage();
//       });
//     } else if (!hasReadPackage && firstPackage) {
//       if (setSliderCurrentIndex) setSliderCurrentIndex(0);
//       if (setIsSliderReading) setIsSliderReading(true);
//       hasReadPackage = true;
//       updateCardReadingStatus(true,0)
//       console.log("Reading first package:", firstPackage);
//       speakNext(firstPackage.readOut, () => {
//       updateCardReadingStatus(false,null)
//         console.log("Finished reading the first package.");
//         updateReadingStatus(false); // Mark reading as complete
//       });
//     } else {
//       updateReadingStatus(false); // Mark reading as complete
//     }
//   };

//   readMessagesAndPackage();
// };
export const handleVoiceOverForMessagesAndPackage = (
  isReadEnabled,
  selectedVoice,
  messages = [],
  firstPackage = null,
  messageObj = null,
  messageIndex = null,
  dispatch,
  setSliderCurrentIndex = null, 
  setIsSliderReading = null,
  subIndex = 0
) => {
  if (!isReadEnabled || !selectedVoice) {
    console.log("Speech synthesis is disabled or no voice selected.");
    return;
  }

  // Ensure messages is always an array
  if (typeof messages === "string") messages = [messages];
  if (!Array.isArray(messages) || messages.length === 0) {
    console.log("No messages to read.");
    return;
  }

  // Stop any ongoing speech synthesis before starting new one
  window.speechSynthesis.cancel();

  let currentIndex = 0;
  let hasReadPackage = false;
  let paused = false;

  const updateReadingStatus = (isReading) => {
    dispatch(
      aiUpdateMessageReadingStatus({
        updatedMessageObj: {
          ...messageObj,
          message: { ...messageObj?.message, isReading, subIndex },
        },
        messageIndex,
      })
    );
  };

  const updateCardReadingStatus = (isReading, cardIndex) => {
    dispatch(
      aiUpdateMessageReadingStatus({
        updatedMessageObj: {
          ...messageObj,
          message: {
            ...messageObj?.message,
            isCardReading: isReading,
            currentReadingCardIndex: cardIndex,
            subIndex,
          },
        },
        messageIndex,
      })
    );
  };

  const speakText = (text, callback) => {
    const utterance = new SpeechSynthesisUtterance(text);
    utterance.voice = selectedVoice;
    utterance.rate = 0.9;

    utterance.onerror = (event) => {
      console.error("Speech synthesis error:", event.error);
      updateReadingStatus(false);
    };

    utterance.onend = () => {
      console.log("Finished reading:", text);
      if (callback) callback();
    };

    setTimeout(() => window.speechSynthesis.speak(utterance), 100);
  };

  const handleVisibilityChange = () => {
    if (document.visibilityState === "hidden") {
      console.log("Tab hidden: Pausing playback.");
      window.speechSynthesis.pause();
      paused = true;
    } else if (document.visibilityState === "visible" && paused) {
      console.log("Tab visible: Resuming playback.");
      window.speechSynthesis.resume();
      paused = false;
    }
  };

  document.addEventListener("visibilitychange", handleVisibilityChange);

  const readMessagesAndPackage = () => {
    if (currentIndex < messages.length) {
      speakText(messages[currentIndex], () => {
        currentIndex += 1;
        readMessagesAndPackage();
      });
    } else if (!hasReadPackage && firstPackage) {
      if (setSliderCurrentIndex) setSliderCurrentIndex(0);
      if (setIsSliderReading) setIsSliderReading(true);
      hasReadPackage = true;

      updateCardReadingStatus(true, 0);
      console.log("Reading first package:", firstPackage);

      speakText(firstPackage.readOut, () => {
        updateCardReadingStatus(false, null);
        console.log("Finished reading the first package.");
        updateReadingStatus(false);
        cleanup();
      });
    } else {
      updateReadingStatus(false);
      cleanup();
    }
  };

  const cleanup = () => {
    document.removeEventListener("visibilitychange", handleVisibilityChange);
  };

  updateReadingStatus(true);
  readMessagesAndPackage();
};

  
  // export const handleVoiceOverForMessagesAndPackage = (
  //   isReadEnabled,
  //   selectedVoice,
  //   messages = [],
  //   firstPackage = null,
  //   messageObj = null,
  //   messageIndex = null,
  //   dispatch,
  //   setSliderCurrentIndex = null,
  //   setIsSliderReading = null,
  // ) => {
  //   if (!isReadEnabled || !subscriptionKey || !region) {
  //     console.log("Speech synthesis is disabled or missing Azure credentials.");
  //     return;
  //   }
  
  //   // Ensure `messages` is always an array
  //   if (typeof messages === "string") {
  //     messages = [messages];
  //   }
  //   if (!Array.isArray(messages) || messages.length === 0) {
  //     console.log("No messages to read.");
  //     return;
  //   }
  
  //   let currentIndex = 0; // Track the current message being read
  //   let hasReadPackage = false; // Ensure the first package is read only once
  //   let recognizerRef = null;
  
  //   // Azure Speech Configuration
  //   // const speechConfig = SpeechSDK.SpeechConfig.fromSubscription(subscriptionKey, region);
  //   // speechConfig.speechSynthesisLanguage = "en-US"; // Set the language
  //   // if (selectedVoice) {
  //     // speechConfig.speechSynthesisVoiceName =  "en-US-JennyNeural"; // Set the voice
  //   // }
  
  //   // Azure Audio Config
  //   // const audioConfig = SpeechSDK.AudioConfig.fromDefaultSpeakerOutput();
  
  //   // Create the synthesizer instance
  //   const synthesizer = initializeSpeechSynthesizer(subscriptionKey, region, selectedVoice);
  
  //   // Create a shallow copy of messageObj and update isReading immutably
  //   const updatedMessageObj = {
  //     ...messageObj,
  //     message: {
  //       ...messageObj?.message,
  //       isReading: true, // Set to true when reading starts
  //     },
  //   };
  
  //   // Dispatch startReading action to indicate reading has started
  //   dispatch(aiUpdateMessageReadingStatus({ updatedMessageObj, messageIndex }));
  
  //   // Function to speak the next message
  //   const speakNext = (text, callback) => {
  //     synthesizer.speakTextAsync(
  //       text,
  //       (result) => {
  //         if (result.reason === SpeechSDK.ResultReason.SynthesizingAudioCompleted) {
  //           console.log("Speech synthesis finished for:", text);
  //           if (callback) callback(); // Call the next step
  //         } else {
  //           console.error("Speech synthesis error:", result.errorDetails);
  //         }
  //       },
  //       (error) => {
  //         console.error("Error during speech synthesis:", error);
  //       }
  //     );
  //   };
  
  //   // Function to handle reading all messages and the package
  //   const readMessagesAndPackage = () => {
  //     if (currentIndex < messages.length) {
  //       // Read the next message
  //       speakNext(messages[currentIndex], () => {
  //         currentIndex += 1;
  //         readMessagesAndPackage(); // Recursively read the next message
  //       });
  //     } else if (!hasReadPackage && firstPackage) {
  //       setSliderCurrentIndex(0);
  //       setIsSliderReading(true);
  //       // Read the first package after messages are done
  //       hasReadPackage = true; // Mark the package as read
  //       console.log("Reading first package:", firstPackage);
  //       speakNext(firstPackage.readOut, () => {
  //         console.log("Finished reading the first package.");
  //       });
  //     } else {
  //       const finalUpdatedMessageObj = {
  //         ...messageObj,
  //         message: {
  //           ...messageObj?.message,
  //           isReading: false, // Set to false when reading is complete
  //         },
  //       };
  
  //       // Dispatch action to update reading status
  //       dispatch(aiUpdateMessageReadingStatus({ updatedMessageObj: finalUpdatedMessageObj, messageIndex }));
  //       console.log("All messages and the first package have been read.");
  //     }
  //   };
  
  //   // Start reading messages and package
  //   readMessagesAndPackage();
  
  //   // Cleanup resources when done
  //   return () => {
  //     if (recognizerRef) {
  //       recognizerRef.close();
  //       recognizerRef = null;
  //     }
  //     synthesizer.close();
  //   };
  // };
// let isSpeaking = false; // Track if speech is ongoing
// let synthesizer = null; // Global reference to the speech synthesizer
// let currentReadingIndex = null; // Track which message is being read
// let player = null; // Track which message is being read

// export const handleAzureVoiceOverForMessagesAndPackage = async (
//   isReadEnabled,
//   selectedVoiceName= "en-US-JennyNeural",
//   messages = [],
//   firstPackage = null,
//   messageObj = null,
//   messageIndex = null,
//   dispatch,
//   setSliderCurrentIndex = null, 
//   setIsSliderReading = null,
//   subIndex = 0
// ) => {
//   if (!isReadEnabled || !selectedVoiceName) {
//     console.log("Speech synthesis is disabled or no voice selected.");
//     return;
//   }

//   if (!AZURE_SPEECH_KEY || !AZURE_SPEECH_REGION) {
//     console.error("Azure Speech Service credentials are missing.");
//     return;
//   }

//   if (typeof messages === "string") messages = [messages];
//   if (!Array.isArray(messages) || messages.length === 0) {
//     console.log("No messages to read.");
//     return;
//   }


//   let currentIndex = 0;
//   let hasReadPackage = false;
//   let paused = false;

//    // **Fix: Use SpeakerAudioDestination**
//    player = new speechsdk.SpeakerAudioDestination();
//    player.onAudioEnd = () => {
//     alert("a")
//      console.log("🔊 Audio playback finished!");
//      isSpeaking = false;
//      currentReadingIndex = null;
//      updateReadingStatus(false); // Mark reading as completed **only after playback finishes**
//    };
//   // Initialize Azure Speech SDK synthesizer
//   const speechConfig = speechsdk.SpeechConfig.fromSubscription(AZURE_SPEECH_KEY, AZURE_SPEECH_REGION);
//   speechConfig.speechSynthesisVoiceName = selectedVoiceName;
//   const audioConfig = speechsdk.AudioConfig.fromDefaultSpeakerOutput();
//   synthesizer = new speechsdk.SpeechSynthesizer(speechConfig, audioConfig);

//   const updateReadingStatus = (isReading, readingIndex = null) => {
//     dispatch(
//       aiUpdateMessageReadingStatus({
//         updatedMessageObj: {
//           ...messageObj,
//           message: { ...messageObj?.message, isReading, subIndex, readingIndex },
//         },
//         messageIndex,
//       })
//     );
//   };

//   const updateCardReadingStatus = (isReading, cardIndex) => {
//     dispatch(
//       aiUpdateMessageReadingStatus({
//         updatedMessageObj: {
//           ...messageObj,
//           message: {
//             ...messageObj?.message,
//             isCardReading: isReading,
//             currentReadingCardIndex: cardIndex,
//             subIndex,
//           },
//         },
//         messageIndex,
//       })
//     );
//   };

//   const speakText = async (text, readingIndex) => {
//     return new Promise((resolve, reject) => {
//       if (!synthesizer || isSpeaking) return resolve();

//       isSpeaking = true; // Mark speech as ongoing
//       currentReadingIndex = readingIndex; // Track which message is being read

//       updateReadingStatus(true, currentReadingIndex); // Update UI with the current message being read

//       const ssmlText = `<speak version='1.0' xml:lang='en-US'>
//         <voice name='${selectedVoiceName}'>${text}</voice>
//       </speak>`;

//       synthesizer.speakSsmlAsync(
//         ssmlText,
//         (result) => {
//           console.log("result",result.reason);
          
//           if (result.reason === speechsdk.ResultReason.SynthesizingAudioCompleted) {
//             console.log("Finished reading:", text);
//             isSpeaking = false;
//             currentReadingIndex = null; // Reset current reading index
//             updateReadingStatus(true, null); // Update UI that this message is done
//             resolve();
//           } else {
//             console.error("Speech synthesis error:", result.errorDetails);
//             updateReadingStatus(false);
//             isSpeaking = false;
//             reject(new Error(result.errorDetails));
//           }
//         },
//         (error) => {
//           console.error("Speech synthesis failed:", error);
//           updateReadingStatus(false);
//           isSpeaking = false;
//           reject(error);
//         }
//       );
//     });
//   };

//   const handleVisibilityChange = () => {
//     if (document.visibilityState === "hidden") {
//       console.log("Tab hidden: Pausing playback.");
//       synthesizer.pauseSpeakingAsync();
//       paused = true;
//     } else if (document.visibilityState === "visible" && paused) {
//       console.log("Tab visible: Resuming playback.");
//       synthesizer.resumeSpeakingAsync();
//       paused = false;
//     }
//   };

//   document.addEventListener("visibilitychange", handleVisibilityChange);

//   const readMessagesAndPackage = async () => {
//     try {
//       for (; currentIndex < messages.length; currentIndex++) {
//         await speakText(messages[currentIndex], currentIndex);
//       }

//       if (!hasReadPackage && firstPackage) {
//         if (setSliderCurrentIndex) setSliderCurrentIndex(0);
//         if (setIsSliderReading) setIsSliderReading(true);
//         hasReadPackage = true;

//         updateCardReadingStatus(true, 0);
//         console.log("Reading first package:", firstPackage);

//         await speakText(firstPackage.readOut, "package");

//         updateCardReadingStatus(false, null);
//         console.log("Finished reading the first package.");
//       }

//       await finalizeReading();
//     } catch (error) {
//       console.error("Error during speech synthesis:", error);
//       await finalizeReading();
//     }
//   };

//   const finalizeReading = async () => {
//     while (isSpeaking) {
//       console.log("Waiting for speech to complete before marking as finished...");
//       await new Promise((resolve) => setTimeout(resolve, 500));
//     }
//     console.log("Finalizing: Marking as completed");
//     updateReadingStatus(false); // Ensure the UI reflects completion **only when done**
//     cleanup();
//   };

//   const cleanup = () => {
//     document.removeEventListener("visibilitychange", handleVisibilityChange);
//     if (synthesizer) {
//       synthesizer.close();
//       synthesizer = null;
//     }
//     isSpeaking = false;
//     currentReadingIndex = null;
//   };

//   updateReadingStatus(true, 0); // Start reading, show UI update
//   await readMessagesAndPackage();
// };

// /**
//  * Stops ongoing speech synthesis immediately and ensures UI updates.
//  */
// export const stopSpeech = async () => {
//   if (synthesizer && isSpeaking) {
//     console.log("Stopping speech...");
//     await new Promise((resolve) => synthesizer.stopSpeakingAsync(() => resolve()));
//     console.log("Speech stopped.");
//     isSpeaking = false;
//     currentReadingIndex = null;
//     updateReadingStatus(false); // Update UI
//     synthesizer.close();
//     synthesizer = null;
//   }
// };


// Global variables to track pause state and audio playback
let isTabInactive = false;
let isSpeechPaused = false;
let lastPlayedTime = 0; // To store the playback position before pausing

// Listen for tab visibility changes
document.addEventListener("visibilitychange", () => {
  if (document.hidden) {
    console.log("⏸️ Tab is inactive, pausing speech...");
    isTabInactive = true;
    pauseSpeech(); // Properly pause instead of stopping
    isSpeechPaused = true;
  } else {
    console.log("▶️ Tab is active, resuming speech...");
    isTabInactive = false;
    if (isSpeechPaused) {
      isSpeechPaused = false;
      resumeSpeech();
    }
  }
});

export const handleAzureVoiceOverForMessagesAndPackage = async (
  isReadEnabled,
  selectedVoiceName = "en-US-JennyNeural",
  messages = [],
  firstPackage = null,
  messageObj = null,
  messageIndex = null,
  dispatch,
  setSliderCurrentIndex = null,
  setIsSliderReading = null,
  subIndex = 0
) => {
  if (isReadEnabled == false) {
    console.log("🚫 Speech synthesis is disabled or no voice selected.");
    return;
  }

  await stopSpeech(); // Ensure any existing speech is stopped before starting a new one

  if (typeof messages === "string") messages = [messages];
  if (!Array.isArray(messages) || messages.length === 0) {
    console.log("⚠️ No messages to read.");
    return;
  }

  let currentIndex = 0;
  let hasReadPackage = false;

  const updateReadingStatus = (isReading, readingIndex = null) => {
    if (!dispatch) return;
    dispatch(
      aiUpdateMessageReadingStatus({
        updatedMessageObj: {
          ...messageObj,
          message: { ...messageObj?.message, isReading, subIndex, readingIndex },
        },
        messageIndex,
      })
    );
  };

  globalUpdateReadingStatus = updateReadingStatus;

  const speakText = async (text, readingIndex) => {
    if (!text || text.trim().length === 0) {
      console.warn(`⚠️ Skipping empty message at index ${readingIndex}`);
      return;
    }

    let retryCount = 0;
    const maxRetries = 3;

    while (retryCount < maxRetries) {
      try {
        if (isTabInactive) {
          console.log("⏸️ Speech paused due to tab inactivity.");
          return;
        }

        updateReadingStatus(true, readingIndex);
        console.log(`🎤 Requesting Azure Speech API for: ${text}`);

        const speechApiUrl = `https://${AZURE_SPEECH_REGION}.tts.speech.microsoft.com/cognitiveservices/v1`;

        const ssml = `
          <speak version='1.0' xml:lang='en-US'>
            <voice name='${selectedVoiceName}'>${text}</voice>
          </speak>`;

        const response = await fetch(speechApiUrl, {
          method: "POST",
          headers: {
            "Ocp-Apim-Subscription-Key": AZURE_SPEECH_KEY,
            "Content-Type": "application/ssml+xml",
            "X-Microsoft-OutputFormat": "audio-16khz-32kbitrate-mono-mp3",
          },
          body: ssml,
        });

        // if (!response.ok) {
        //   console.error(`Azure Speech API error: ${response.status} - ${await response.text()}`);
        //   retryCount++;
        //   console.log(`🔄 Retrying (${retryCount}/${maxRetries}) for: ${text}`);
        //   await new Promise((resolve) => setTimeout(resolve, 1000 * retryCount));
        //   continue;
        // }

        if (!response.ok) {
          const errorMessage = await response.text();
          console.error(`❌ Azure Speech API error: ${response.status} - ${errorMessage}`);
          
          // Handle 400 error
          if (response.status === 400) {
            alert(`🚨 Error: Bad request. Invalid SSML or message: "${text}"`);
            return; // Skip the message instead of retrying
          }

          retryCount++;
          console.log(`🔄 Retrying (${retryCount}/${maxRetries}) for: ${text}`);
          await new Promise((resolve) => setTimeout(resolve, 1000 * retryCount));
          continue;
        }

        const audioBlob = await response.blob();
        const audioUrl = URL.createObjectURL(audioBlob);

        if (audioElement) {
          audioElement.pause();
          audioElement.src = "";
        }

        audioElement = new Audio(audioUrl);

        return new Promise((resolve) => {
          audioElement.onplay = () => {
            console.log("▶️ Speech resumed from pause.");
          };

          audioElement.onpause = () => {
            console.log("⏸️ Speech is paused.");
          };

          audioElement.onended = async () => {
            console.log("🔊 Audio playback finished!");
            updateReadingStatus(false);
            await stopSpeech()
            resolve();
          };

          audioElement.play();
        });

      } catch (error) {
        console.error("❌ Error during speech synthesis:", error);
        retryCount++;
        console.log(`🔄 Retrying (${retryCount}/${maxRetries}) for: ${text}`);
        await new Promise((resolve) => setTimeout(resolve, 1000 * retryCount));
      }
    }

    console.error("❌ Speech API failed after multiple retries.");
    updateReadingStatus(false);
  };

  const readMessagesAndPackage = async () => {
    try {
      for (; currentIndex < messages.length; currentIndex++) {
        await speakText(messages[currentIndex], currentIndex);
        if (isTabInactive) {
          console.log("⏸️ Stopped speech due to tab inactivity.");
          return;
        }
      }

      // if (!hasReadPackage && firstPackage) {
      //   if (setSliderCurrentIndex) setSliderCurrentIndex(0);
      //   if (setIsSliderReading) setIsSliderReading(true);
      //   hasReadPackage = true;

      //   console.log("📦 Reading first package...");
      //   await speakText(firstPackage.readOut, "package");

      //   console.log("📦 Finished reading the package.");
      // }
    } catch (error) {
      console.error("❌ Error during speech synthesis:", error);
    }
  };

  console.log("📢 Starting speech...");
  updateReadingStatus(true, 0);
  await readMessagesAndPackage();
};

/**
 * **Pauses** ongoing speech playback (instead of stopping).
 */
export const pauseSpeech = async () => {
  if (audioElement && !audioElement.paused) {
    console.log("⏸️ Pausing speech...");
    lastPlayedTime = audioElement.currentTime; // Save the current playback time
    audioElement.pause();
  }
};

/**
 * **Resumes** paused speech playback.
 */
export const resumeSpeech = async () => {
  if (audioElement && audioElement.paused) {
    console.log("▶️ Resuming speech from", lastPlayedTime, "seconds.");
    audioElement.currentTime = lastPlayedTime; // Resume from where it was paused
    audioElement.play();
  }
};

/**
 * **Stops** speech completely.
 */
export const stopSpeech = async () => {
  if (audioElement) {
    console.log("⏹️ Stopping speech...");
    audioElement.pause();
    audioElement.src = "";
    lastPlayedTime = 0;
    if (globalUpdateReadingStatus) {
      globalUpdateReadingStatus(false);
    }
  }
};


const AiShoppingChatInfo = (props) => {
    const { isReadEnabled,selectedVoice,setSliderCurrentIndex,
      isSliderReading } = useReadContext();
    const dispatch = useDispatch();
  const {
    aiAsstMessageList = [],
    aiAsstResponseData = [],
    userSelectedQuestions = {},
    handleClickSubmitFormData = () => { },
    handleOnchangeSubmitFormData = () => { },
    handleSugessionPromptFormData = () => { },
  } = props;
// console.log("aiAsstMessageList",aiAsstMessageList);

  const getUserChatDesign = (msgList, i) => (
    <div className="" key={i}>
      <div className="chatbot-user-chat-message-container">
        <div className="chatbot-user-chat-message">
          {msgList.message}
          <div className="chatbot-user-chat-message-arrow"></div>
        </div>
        <div className="chatbot-user-avatar">
          <div className="chatbot-user-avatar-container">
            <img
              src={CustomerIcon}
              alt="CustomerIcon"
              className="chatbot-user-avatar-icon"
            />
          </div>
        </div>
      </div>
    </div>
  );

  const getUserDesign = (msgList, i) => getUserChatDesign(msgList, i);

  const handleRedirect = (event) => {
    event.preventDefault();
    window.open('https://www.cheapcaribbean.com/', '_blank');
  };

  const getAiAssistMessageDesign = (assistant = "", i,messageList) => (
    <div className="chatbot-chat-bot-message-container" key={i} id={`message-${i}`}>
      <div className="chatbot-chat-bot-avatar">
        <div className="chatbot-chat-bot-avatar-container">
          <img src={BotIcon} alt="Bot-Icon" />
        </div>
      </div>
      <div
        className="chatbot-chat-bot-message"
        style={{ background: "#fff" }}
      >
        {assistant?.split('\n')?.map((line, lineIndex) => {
          const linkedText = line?.replace(
            /\bCheap\s*Caribbean\b|\bcheap\s*caribbean\b/gi,
            '<a href="#" onclick="return false;" class="cheap-caribbean-link">Cheap Caribbean</a>'
          );
          return <><div
            key={lineIndex}
            dangerouslySetInnerHTML={{ __html: linkedText }}
            onClick={(e) => {
              if (e.target && e.target.className === 'cheap-caribbean-link') {
                handleRedirect(e);
              }
            }}
          />
          {isReadEnabled && (
             <Tooltip  enterTouchDelay={0}
             leaveTouchDelay={5000}
             placement="top"
             componentsProps={{
               tooltip: {
                 sx: {
                   bgcolor: "rgba(97, 97, 97, 0.92) !important",
                   color: "white",
                   fontSize: "14px",
                 },
               },
             }}
             slotProps={{
               popper: {
                 modifiers: [
                   {
                     name: "offset",
                     options: {
                       offset: [0, 0], // Centered directly above the button
                     },
                   },
                 ],
               },
             }}
              title={messageList.message.isReading ? 'Stop Reading' : 'Start Reading'}>
            <div
              className={`chatbot-chat-bot-message-voice-icon ${messageList.message.isReading && messageList.message.subIndex == 0 ? 'bg-danger' : ''}`}
              onClick={async () => {
                // console.log("messageList",messageList);
                if (!messageList.message.isReading) {
                  handleAzureVoiceOverForMessagesAndPackage(
                    isReadEnabled,
                    'en-US-JennyNeural',
                    messageList.message.assistant,
                    messageList.message.firstPackage,
                    messageList,
                    i, // Assuming 'i' is the index of the current message
                    dispatch,
                    setSliderCurrentIndex,
                    isSliderReading,
                    0
                  );
                } else {
                   const finalUpdatedMessageObj = {
                    ...messageList,
                    message: {
                      ...messageList?.message,
                      isReading: false, // Set to false when reading is complete
                    },
                  };
                  // Stop current reading
                  window.speechSynthesis.cancel();
                  await stopSpeech();
                  dispatch(aiUpdateMessageReadingStatus(finalUpdatedMessageObj, i));
                }
              }}
            >
              {messageList.message.isReading && messageList.message.subIndex == 0 ? <StopCircleIcon /> : <GraphicEqIcon />}
            </div>
            </Tooltip> 

          )}
          </>
        })}
        <div
          className="chatbot-chat-bot-message-arrow"
          style={{ borderRightColor: "#fff" }}
        > </div>
      </div>
    </div>
  );

  const getAiDesign = (msgList, i) => (
    <>
    {/* {<pre>{JSON.stringify(msgList?.message?.suggestionComponent)}</pre>} */}
      {msgList?.message?.suggestionComponent?.componentType == '' && getAiAssistMessageDesign(msgList.message.assistant,i,msgList)}
      {msgList?.message?.suggestionComponent?.componentType == '' && <AiShoppingCarousel responseInfo={{...msgList,currentMessageIndex:i}} />}
      {getUserSelectedCtrlInfo(msgList, i)}
    </>
  );

  const getUserSelectedCtrlInfo = (msgList, i) => {
    // console.log("msgList",msgList);
    
    if (msgList.ctrlData != undefined && msgList.ctrlData.suggestionCd) {
      return (
        <>
          {msgList.ctrlData.counterPrompt && msgList.ctrlData.counterPrompt !== msgList.message.assistant && (
            <div className="chatbot-chat-bot-message-container" key={i} id={`message-${i}`}> 
              <div className="chatbot-chat-bot-avatar">
                <div className="chatbot-chat-bot-avatar-container">
                  <img src={BotIcon} alt="Bot-Icon" />
                </div>
              </div>
              <div
                className="chatbot-chat-bot-message"
                style={{ background: "#fff" }}
              >
                {msgList.ctrlData.counterPrompt}
                <div
                  className="chatbot-chat-bot-message-arrow"
                  style={{ borderRightColor: "#fff" }}
                ></div>
                 {isReadEnabled && (
            <div
              className={`chatbot-chat-bot-message-voice-icon ${msgList.message.isReading && msgList.message.subIndex == 1 ? 'bg-danger' : ''}`}
              onClick={async () => {
                if (!msgList.message.isReading) {
                  // Start voice-over reading
                  handleAzureVoiceOverForMessagesAndPackage(
                    isReadEnabled,
                    "en-US-JennyNeural",
                    msgList.message.Readablemessage,
                    msgList.message.firstPackage,
                    msgList,
                    i, // Assuming 'i' is the index of the current message
                    dispatch,
                    setSliderCurrentIndex,
                    isSliderReading,
                    1
                  );
                } else {
                  const finalUpdatedMessageObj = {
                    ...msgList,
                    message: {
                      ...msgList?.message,
                      isReading: false, // Set to false when reading is complete
                    },
                  };
                 await stopSpeech();
                  // Stop current reading
                  window.speechSynthesis.cancel();
                  dispatch(aiUpdateMessageReadingStatus(finalUpdatedMessageObj, i));
                }
              }}
            >
              {msgList.message.isReading && msgList.message.subIndex == 1 ? <StopCircleIcon /> : <GraphicEqIcon />}
            </div>
          )}
              </div>
            </div>
          )}
          <WidgetInfo
            ctrlData={msgList}
            messageListIndex={i}
            aiAsstResponse={aiAsstResponseData}
            userSelectedQuestions={userSelectedQuestions}
            handleClickSubmitFormData={handleClickSubmitFormData}
            handleOnchangeSubmitFormData={handleOnchangeSubmitFormData}
            handleSugessionPromptFormData={handleSugessionPromptFormData}
          />
        </>
      );
    }
  };

  const getChatDesign = (msgList, i) => {
    if (msgList.msgType === "userChat") {
      return getUserDesign(msgList, i);
    } else {
      return getAiDesign(msgList, i);
    }
  };

  return (
    <>
      {aiAsstMessageList.map((messageList, index) => (
        <div key={index}>{getChatDesign(messageList, index)}</div>
      ))}
    </>
  );
};

export default AiShoppingChatInfo;
