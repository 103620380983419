import moment from "moment";
const getDateCurToReqFormat = (date, currentFormat, requiredFormat) => {
    return date ? moment(date, currentFormat).format(requiredFormat) : "";
}

const getMuiCtrlDateFormat = (date, currentFormat) => {
    return date ? moment(date, "YYYY-MM-DD").toDate() : "";
}

const getDataTableFilter = (recData, filters) => {
    let filterData = recData.filter(data => {
        return Object.keys(filters).some(keyName => data[keyName].toString().toLowerCase().indexOf(filters[keyName].toString().toLowerCase()) > -1);
    });
    return filterData;
}
const getCurrentTime = () => {
    return moment().format('LT');
}

const getAddNumberOfDays = (currentDate, currentDateFormat, noDays) => {
    //YYYY-MM-DD"
    return moment(currentDate, currentDateFormat).add(noDays, 'days');
}

const getDifferentDays = (dateFirst = "", formatOne = "", dateSecond = "", formatTwo = "") => {
    if (dateFirst && dateSecond) {
        let dateOne = moment(dateFirst, formatOne);
        let dateTwo = moment(dateSecond, formatTwo);
        return dateTwo.diff(dateOne, 'days')
    } return "";
}

const getCheckCurrentDate = (dateFirst = "", formatOne = "", dateSecond = "", formatTwo = "") => {
    let currentDate = moment().add(1, 'days').format("YYYY-MM-DD");
    if (currentDate) {
        let differentDays = getDifferentDays(currentDate, formatOne, dateFirst, formatOne);
        if (10 >= differentDays) {
            return true;
        } else {
            return false;
        }
    } else {
        return false;
    }
}



const getNormalTo24hoursFormatedTime = (time) => {
    return moment(time, 'hh:mm A').format('HH:mm');
}

const get24hoursToNormalFormatedTime = (time) => {
    return moment(time, 'HH:mm').format('hh:mm A');
}

const dateHelper = {
    getDateCurToReqFormat, getCurrentTime, getMuiCtrlDateFormat,
    getDataTableFilter, getAddNumberOfDays, getDifferentDays, getCheckCurrentDate,
    getNormalTo24hoursFormatedTime, get24hoursToNormalFormatedTime

};
export default dateHelper;