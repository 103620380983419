import './App.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React, { useState, useEffect } from 'react';
import { SnackbarProvider } from 'notistack';
import AppRoute from './route/AppRoute';
import { Provider } from 'react-redux';
import store from './store/index';
import { ReadProvider } from './common/context/ReadContext';
import FloatingOptions from './components/ai-shopping/FloatingOptions';
import loaderImage from '../src/assets/img/spinner-loader.gif';  // Add your loader image in the assets folder

function App() {
  return (
    <React.Fragment>
        <SnackbarProvider maxSnack={3}>
          <Provider store={store}>
            <ReadProvider>
              {/* <FloatingOptions /> */}
              <AppRoute />
            </ReadProvider>
          </Provider>
        </SnackbarProvider>
    </React.Fragment>
  );
}

export default App;
