import Slider from "@mui/material/Slider";
import Box from "@mui/material/Box";
import Button from "@material-ui/core/Button";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useSpeechSynthesis } from "react-speech-kit";
import {
  aiChat,
  userChat,
  userSelectedQuestionEvent,
  userSelectedQuestionListEvent,
  updateLoadingStatus,
  chatListUpdation,
  clearUserSelectedPreferencesQuestionStateValue,
} from "../../store/slices/shoppingAssistanceSlice";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import dateHelper from "../../common/helpers/date/dateHelper";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";

const WidgetInfo = (props) => {
  const {
    ctrlData,
    aiAsstResponse,
    userSelectedQuestions,
    messageListIndex,
    handleOnchangeSubmitFormData,
    handleClickSubmitFormData,
  } = props;

  const dispatch = useDispatch();
  const priceRange = aiAsstResponse.pricerange;
  const selectedQuestions = userSelectedQuestions;

  const todayDate = new Date();
  todayDate.setDate(todayDate.getDate() + 1);

  const futureDate = new Date(todayDate);
  futureDate.setDate(todayDate.getDate() + 330);

  const selector = useSelector((state) => state);
  const aiAsstMessage = selector.aiShoppingInfo.aiAsstMessage;
  const disableNights = selector.aiShoppingInfo.disabledNights;
  const aiShoppingInfoLoadingStatus = selector.aiShoppingInfo?.loading;
  const [activitiesListCount, setEActivitiesListCount] = useState("");
  const [preferencesValue, setPreferencesValue] = useState("");
  const { cancel } = useSpeechSynthesis(); 
  useEffect(() => {
    if (ctrlData?.isCtrlActive && !ctrlData?.selectedValues) {
      setEActivitiesListCount(
        ctrlData?.ctrlData?.preferedActivityList?.length > 8
          ? 5
          : ctrlData?.ctrlData?.preferedActivityList?.length
      );
    } else if (
      ctrlData?.ctrlData?.componentType === "List" &&
      ctrlData?.selectedValues
    ) {
      const AllSelectedListValues = Object.keys(ctrlData?.selectedValues);
      const IndexesList = AllSelectedListValues.map((eachValue) =>
        ctrlData?.ctrlData?.preferedActivityList?.findIndex(
          (eachActivity) => eachActivity?.preferedActivity === eachValue
        )
      );
      const maxIndexValue = Math.max(...IndexesList);
      const activeListCount =
        maxIndexValue > 4
          ? ctrlData?.ctrlData?.preferedActivityList?.length
          : ctrlData?.ctrlData?.preferedActivityList?.length > 8
          ? 5
          : ctrlData?.ctrlData?.preferedActivityList?.length;

      setEActivitiesListCount(activeListCount);
    }
  }, [ctrlData]);

  const handleSelectedCtrlData = (event, newValue, activeThumb) => {
    const minRange = 1000;
    const value1 = selectedQuestions.price_range;
    if (!Array.isArray(newValue)) return;

    const ctrlData = {
      key_name: event.target.name,
      key_value:
        activeThumb === 0
          ? [Math.min(newValue[0], value1[1] - minRange), value1[1]]
          : [value1[0], Math.max(newValue[1], value1[0] + minRange)],
    };

    dispatch(userSelectedQuestionEvent(ctrlData));
  };

  const handleSelectedCtrlDateData = (dates, ctrlType) => {
    const [start, end] = dates;
    const ctrlData = { key_name: "travel_dates", key_value: dates };

    dispatch(userSelectedQuestionEvent(ctrlData));
    if (start !== null && end !== null) {
      onChangeChatList(true, dates);
      dispatch(
        userChat({
          msgType: "userChat",
          message: `Selected Dates from ${dateHelper.getDateCurToReqFormat(
            start,
            "LLLL",
            "MM-DD-YYYY"
          )} to ${dateHelper.getDateCurToReqFormat(end, "LLLL", "MM-DD-YYYY")}`,
          ctrlData: "",
        })
      );
      handleOnchangeCtrlInfoData(dates, ctrlType);
    }
  };

  const handleOnchangeCtrlInfoData = (dataInfo, ctrlType) => {
    handleOnchangeSubmitFormData(dataInfo, ctrlType);
  };

  const handleUserSelectedCtrlData = (ctrlDataInfo, ctrlType) => {
    console.log("ctrlType",ctrlType);
    if (ctrlType === "PR") {
      onChangeChatList(true, ctrlDataInfo);
      dispatch(
        userChat({
          msgType: "userChat",
          message: `Selected price range from $${ctrlDataInfo[0]} to $${ctrlDataInfo[1]}`,
          ctrlData: "",
        })
      );
    } else if (ctrlType === "TD") {
      dispatch(
        userChat({
          msgType: "userChat",
          message: `Selected Dates from ${dateHelper.getDateCurToReqFormat(
            ctrlDataInfo[0],
            "LLLL",
            "MM-DD-YYYY"
          )} to ${dateHelper.getDateCurToReqFormat(
            ctrlDataInfo[1],
            "LLLL",
            "MM-DD-YYYY"
          )}`,
          ctrlData: "",
        })
      );
    }
    handleSubmitFormData(ctrlType);
  };

  const handleSubmitFormData = (ctrlType) => {
    handleClickSubmitFormData(ctrlType);
  };

  const valuetext = (value) => `$${value}`;

  const handleSelectedListTypeData = (list, ctrlType, numberOfSelection) => {
    let reset = false;
    const filteredMessagesList = aiAsstMessage.map((eachMessage, i) => {
      if (i === messageListIndex) {
        const hasValue =
          eachMessage.selectedValues?.hasOwnProperty(list["preferedActivity"]) ||
          false;
        reset = hasValue;
        if (numberOfSelection === 1) {
          return {
            ...eachMessage,
            selectedValues: hasValue
              ? {}
              : { [list["preferedActivity"]]: true },
          };
        } else {
          if (hasValue) {
            const updatedValues = { ...eachMessage.selectedValues };
            delete updatedValues[list["preferedActivity"]];
            return { ...eachMessage, selectedValues: updatedValues };
          } else {
            return {
              ...eachMessage,
              selectedValues: {
                ...eachMessage.selectedValues,
                [list["preferedActivity"]]: true,
              },
            };
          }
        }
      } else {
        return eachMessage;
      }
    });

    const ctrlData = {
      key_name: "prefered_activity_list",
      key_value: reset
        ? numberOfSelection === 1
          ? ""
          : list.preferedActivity
        : list.preferedActivity,
      ctrlType,
      numberOfSelection,
    };

    dispatch(chatListUpdation(filteredMessagesList));
    dispatch(userSelectedQuestionListEvent(ctrlData));
  };

  const onChangeChatList = (isCtrl = "", isValues = "") => {
    const filteredMessagesList = aiAsstMessage.map((eachMessage, i) => {
      if (i === messageListIndex) {
        let updatedMessage = { ...eachMessage };
        if (isCtrl) updatedMessage.isCtrlActive = false;
        if (isValues) updatedMessage.selectedValues = isValues;
        return updatedMessage;
      } else {
        return eachMessage;
      }
    });
    dispatch(chatListUpdation(filteredMessagesList));
  };

  const handleUserSelectedListTypeCtrlData = (selectedData, ctrlType, data) => {
    onChangeChatList(true);
    if (data && data.length > 0) {
      dispatch(
        userChat({
          msgType: "userChat",
          message: data.join(", "),
          isCtrlActive: false,
        })
      );
    }
    dispatch(updateLoadingStatus({ status: true }));
    handleSubmitFormData(ctrlType);
  };

  const handleSelectChatPreferencesValue = (event, list) => {
    const selectedValue = event.target.value;
    setPreferencesValue(selectedValue);
    onChangeChatList(true);
    dispatch(
      userChat({
        msgType: "userChat",
        message: list.sugessionText,
        isCtrlActive: false,
      })
    );
    if (list.suggestionCd === "PR") {
      dispatch(
        clearUserSelectedPreferencesQuestionStateValue({
          key_name: "price_range",
          key_value: [priceRange.min, priceRange.max],
        })
      );
    } else if (list.suggestionCd === "TD") {
      dispatch(
        clearUserSelectedPreferencesQuestionStateValue({
          key_name: "travel_dates",
          key_value: [null, null],
        })
      );
    }
    dispatch(
      aiChat({
        msgType: "aiChat",
        message: {
          assistant: list.sugessionText,
          suggestionComponent: {
            ...list,
            suggestionPrompt: list.sugessionText,
            counterPrompt: "",
          },
        },
        isCtrlActive: true,
        ctrlData: {
          ...list,
          counterPrompt: list.sugessionText,
          suggestionPrompt: list.sugessionText,
        },
      })
    );
  };

  const getUserSelectedCtrls = (ctrlInfo) => {
    let ctrlType =
      ctrlInfo.ctrlData.componentType === "List"
        ? ctrlInfo.ctrlData.componentType
        : ctrlInfo.ctrlData.suggestionCd;

    const ListType = ["List", "TDS", "PD", "TH", "AT", "AM"].includes(ctrlType)
      ? "List"
      : ctrlType;

    let ctrlName = "";
    switch (ListType) {
      case "PR":
        const v = ctrlInfo.selectedValues?.length
          ? ctrlInfo.selectedValues
          : selectedQuestions.price_range;
        ctrlName = (
          <Box sx={{ width: 300, maxWidth: "100%" }} className="mx-auto card p-4 m">
            <div className="d-flex flex-wrap">
              <Slider
                className="custom-price-range-clss my-2"
                name="price_range"
                value={v}
                min={priceRange.min}
                max={priceRange.max}
                step={100}
                disableSwap
                valueLabelFormat={valuetext}
                onChange={handleSelectedCtrlData}
                valueLabelDisplay="on"
                getAriaValueText={valuetext}
                sx={{
                  "& .MuiSlider-thumb": { color: "#db487e" },
                  "& .MuiSlider-track": { color: "#25C9D0" },
                  "& .MuiSlider-rail": { color: "#0000001f" },
                  "& .MuiTooltip-tooltip": { backgroundColor: "blue" },
                }}
                disabled={!ctrlInfo.isCtrlActive || aiShoppingInfoLoadingStatus}
              />
            </div>
            <Button
              size="small"
              variant="outlined"
              className="AIcustomCheckBox"
              disabled={!ctrlInfo.isCtrlActive || aiShoppingInfoLoadingStatus} 
              onClick={() => {
                cancel()
                handleUserSelectedCtrlData(selectedQuestions.price_range, ctrlType)}
              }
            >
              Confirm
            </Button>
          </Box>
        );
        break;
      case "TD":
        ctrlName = (
          <Box sx={{ width: 300, maxWidth: "100%" }} className="mx-auto card">
            <div className={`react-datepicker-container-class d-flex flex-wrap ${!ctrlInfo.isCtrlActive || aiShoppingInfoLoadingStatus ? "disabledDiv" : ""}`}>
              <DatePicker
                name="travel_dates"
                onChange={(data) => handleSelectedCtrlDateData(data, ctrlType)}
                startDate={ctrlInfo.selectedValues?.[0] || selectedQuestions.travel_dates[0]}
                endDate={ctrlInfo.selectedValues?.[1] || selectedQuestions.travel_dates[1]}
                selectsRange
                inline
                minDate={todayDate}
                maxDate={futureDate}
              />
            </div>
          </Box>
        );
        break;
      case "List":
        console.log("ctrlInfo?.ctrlData?.preferedActivityList",ctrlInfo?.ctrlData?.preferedActivityList);
        ctrlName = (
          <div className="AIoptions">
            <div className="AIoptions-container" disabled={!ctrlInfo.isCtrlActive || aiShoppingInfoLoadingStatus}>
              {ctrlInfo?.ctrlData?.preferedActivityList?.slice(0, activitiesListCount)?.map((list, listIndex) => (
                <div
                  key={listIndex}
                  className={`${ctrlInfo.selectedValues?.hasOwnProperty(list.preferedActivity) ? "AIoptions-item item-active" : "AIoptions-item"} ${!ctrlInfo.isCtrlActive || aiShoppingInfoLoadingStatus || disableNights.includes(list.preferedActivity)   ? "disabledDiv" : ""}`}
                  onClick={() => handleSelectedListTypeData(list, ctrlType, ctrlInfo.ctrlData.numberOfSelection)}
                
                >
                  {list.preferedActivity}
                </div>
              ))}
              {ctrlInfo?.ctrlData?.preferedActivityList?.length > 8 && activitiesListCount === 5 && (
                <Button
                  size="small"
                  variant="outlined"
                  className="AIcustomCheckBox"
                  onClick={() => {
                    cancel();
                    setEActivitiesListCount(ctrlInfo?.ctrlData?.preferedActivityList?.length)}
                  }
                  disabled={!ctrlInfo?.isCtrlActive || aiShoppingInfoLoadingStatus}
                >
                  More
                </Button>
              )}
            </div>
            <div className="d-flex justify-content-center">
              {ctrlInfo?.ctrlData?.preferedActivityList?.length > 0 && selectedQuestions?.prefered_activity_list && Object.keys(selectedQuestions?.prefered_activity_list)?.length > 0 && (
                <Button
                  size="small"
                  variant="outlined"
                  className="AIcustomCheckBox"
                  onClick={() => {
                    cancel();
                    handleUserSelectedListTypeCtrlData(selectedQuestions.activities, ctrlType + "-" + ctrlInfo.ctrlData.suggestionCd, Object.keys(selectedQuestions?.prefered_activity_list))}
                  }
                  disabled={!ctrlInfo?.isCtrlActive || aiShoppingInfoLoadingStatus}
                >
                  Confirm
                </Button>
              )}
            </div>
          </div>
        );
        break;
      case "CP":
        const cps = ctrlInfo.message.chatPreferences;
        ctrlName = (
          <div className="AIoptions d-flex flex-row justify-content-center">
            <FormControl disabled={!ctrlInfo.isCtrlActive || aiShoppingInfoLoadingStatus} className="ai-preferences-options d-flex flex-row justify-content-center p-3 flex-wrap shadow-sm">
              {cps.map((list, listIndex) => (
                <FormControlLabel
                  key={`${list}+ ${listIndex}`}
                  value={list.sugessionText}
                  control={
                    <Radio
                      size="small"
                      checked={preferencesValue === list.sugessionText}
                      onChange={(e) => handleSelectChatPreferencesValue(e, list)}
                    />
                  }
                  label={list.sugessionText}
                  className="preferences-radio-btn-class"
                />
              ))}
            </FormControl>
          </div>
        );
        break;
      default:
        ctrlName = "";
    }
    return ctrlName;
  };

  return <>{getUserSelectedCtrls(ctrlData)}</>;
};

export default WidgetInfo;
