import React, { useEffect, useState, useRef, useMemo } from "react";
import axios from "axios";
import Button from "@material-ui/core/Button";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { FormControl, InputLabel, MenuItem, Select, Checkbox, ListItemText } from "@mui/material";
import DialogTitle from '@mui/material/DialogTitle';
import Icon from '@mui/material/Icon';
import "../../assets/css/ai_style.css";
import mapIcon from "../../assets/img/map-icon.png";
import starIcon from "../../assets/img/star-icon.png";
import twoStars from "../../assets/img/two-stars.png";
import closeBtn from "../../assets/img/close-btn.png";
import FlightIcon from '@mui/icons-material/Flight';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import StarsIcon from '@mui/icons-material/Stars';
import CloseIcon from '@mui/icons-material/Close';
import HotelIcon from '@mui/icons-material/Hotel';

import "../../assets/css/show_all_dialog.css"


const ShowAllDealDates = ({ showDeal = false, handleClose = () => { }, selectedHotelItem = null, priceCacheItems, nightOptions, daysOptions }) => {
    const weekOrder = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

    // select by price 
    const [selectedValue, setSelectedValue] = useState("");
    const handleRelevantChange = (event) => {
        setSelectedValue(event.target.value);
    };
    const firstResultRef = useRef(null);
    const [sortOption, setSortOption] = useState("Price Low to High");
    const [lengthOfStay, setLengthOfStay] = useState(nightOptions); // ✅ Default: All Nights Selected
    const [departureDays, setDepartureDays] = useState([]); // Default Any
    const [allNightsSelected, setAllNightsSelected] = useState(false); // Default: All selected
    const [availabileDayOptions,setAvailabileDayOptions] = useState([]);
    const handleSortChange = (event) => {
        setSortOption(event.target.value);
    };

    const handleLengthChange = (event) => {
        const selectedValues = event.target.value;
        setDepartureDays([]);
        // ✅ If user deselects everything, set "Any" (empty array)
        if (selectedValues.length === 0) {
            setLengthOfStay([]);
            return;
        }

        // ✅ Store selected values
        setLengthOfStay(selectedValues);
    };

    const handleDepartureDayChange = (event) => {
        const selectedValues = event.target.value;
        if (selectedValues.length === 0) {
            setDepartureDays([]); // Reset to All Nights
        } else {
            const sortedDays = selectedValues.sort((a, b) => weekOrder.indexOf(a) - weekOrder.indexOf(b));
            setDepartureDays(sortedDays);
        }
    };
    // const { uniqueNights, uniqueDays, bestDeals } = useMemo(() => {
    //     // ✅ Extract unique nights available
    //     const uniqueNights = [...new Set(prD.map(deal => deal.n.toString()))].sort((a, b) => a - b);



    //     let allTDs = prD.flatMap(deal => 
    //         deal.td.map(trip => ({
    //             ...trip,
    //             nights: deal.n,
    //             pkgid: deal.pkgid,
    //             departureDate: new Date(trip.d.substring(0, 4), trip.d.substring(4, 6) - 1, trip.d.substring(6, 8))
    //                 .toLocaleDateString("en-US", { weekday: "short", month: "short", day: "2-digit" })
    //         }))
    //     );

    //     // ✅ Sort by price (lowest first)
    //     allTDs.sort((a, b) => a.p - b.p);

    //     // ✅ Extract top 3 unique lowest prices
    //     const uniquePrices = new Set();
    //     const bestDeals = allTDs.filter(td => {
    //         if (uniquePrices.size < 3 && !uniquePrices.has(td.p)) {
    //             uniquePrices.add(td.p);
    //             return true;
    //         }
    //         return false;
    //     });

    //     return { uniqueNights, uniqueDays, bestDeals };
    // }, [prD]);



    // useEffect(()=>{
    //     if(showDeal){
    //         fetchHodelCardDeals()
    //     }
    // },[showDeal])

    // **Filter and Sort Logic**
    const filteredDeals = useMemo(() => {
        let deals = lengthOfStay.length > 0
            ? priceCacheItems.filter(deal => lengthOfStay.includes(deal.n.toString()))
            : priceCacheItems;
    
        let allTDs = deals.flatMap(deal => {
            let filteredTD = deal.td.map(trip => {
                const tripDate = new Date(
                    trip.d.substring(0, 4),
                    trip.d.substring(4, 6) - 1,
                    trip.d.substring(6, 8)
                );
    
                const returnDate = new Date(tripDate);
                returnDate.setDate(tripDate.getDate() + deal.n);
    
                return {
                    ...trip,
                    nights: deal.n,
                    pkgid: deal.pkgid, // Keep for reference but don't enforce uniqueness
                    departureDate: tripDate.toLocaleDateString("en-US", { weekday: "short", month: "short", day: "2-digit" }),
                    returnDate: returnDate.toLocaleDateString("en-US", { weekday: "short", month: "short", day: "2-digit" }),
                    tripDate: tripDate,
                    departureDateBooking: tripDate.toISOString().split('T')[0],
                    returnDateBooking: returnDate.toISOString().split('T')[0],
                    price: trip.p // Explicit price property for sorting
                };
            });
    
            if (departureDays.length > 0) {
                filteredTD = filteredTD.filter(trip =>
                    departureDays.includes(trip.tripDate.toLocaleString("en-US", { weekday: "long" }))
                );
            }
    
            return filteredTD;
        });
    
        
        // ✅ Sorting Logic (Including Sorting by Date)
        switch (sortOption) {
            case "Price Low to High":
                allTDs.sort((a, b) => a.price - b.price);
                break;
            case "Price High to Low":
                allTDs.sort((a, b) => b.price - a.price);
                break;
            case "Nights Ascending":
                // allTDs.sort((a, b) => (a.nights || 0) - (b.nights || 0));
                allTDs.sort((a, b) => {
                    if (a.nights !== b.nights) {
                        return a.nights - b.nights; // First, sort by nights (Ascending)
                    }
                    return a.price - b.price; // Then, sort by price (Ascending)
                });
                break;
            case "Nights Descending":
                // allTDs.sort((a, b) => (b.nights || 0) - (a.nights || 0));
                allTDs.sort((a, b) => {
                    if (a.nights !== b.nights) {
                        return b.nights - a.nights; // First, sort by nights (Descending)
                    }
                    return a.price - b.price; // Then, sort by price (Ascending)
                });
                break;
            case "Date": // ✅ Sort by Departure Date (Oldest First)
                allTDs.sort((a, b) => a.tripDate - b.tripDate);
                break;
            case "Departure Date Descending": // ✅ Sort by Departure Date (Newest First)
                allTDs.sort((a, b) => b.tripDate - a.tripDate);
                break;
            default:
                break;
        }

        // ✅ Sort all deals by price (ascending order)
        const sortedByPrice = [...allTDs].sort((a, b) => a.p - b.p);
        // allTDs.sort((a, b) => a.price - b.price);
    
        // ✅ Select the top 3 lowest-priced deals (no uniqueness constraint)
        const bestDeals = sortedByPrice.slice(0, 3);
    
        // ✅ Mark `isBestDeal` for selected best deals
        allTDs = allTDs.map(td => ({
            ...td,
            isBestDeal: bestDeals.some(deal => deal.price === td.price && deal.departureDateBooking === td.departureDateBooking)
        }));
    
        // ✅ Remove `tripDate` (extra data) before returning
        // allTDs = allTDs.map(({ tripDate, ...rest }) => rest);
    
        // console.log("Filtered Deals:", allTDs);
        if (firstResultRef.current) {
            firstResultRef.current.scrollIntoView({
                behavior: "smooth", 
                block: "start"
            });
        }
    
        return allTDs;
    }, [sortOption, lengthOfStay, departureDays]);


    useEffect(() => {
        let availableDepartureDays = [...new Set(filteredDeals.map(trip =>
            trip.tripDate.toLocaleString("en-US", { weekday: "long" })
        ))];
    
        // ✅ Sort departureDays based on `weekOrder`
       const sortOption = availableDepartureDays.sort((a, b) => weekOrder.indexOf(a) - weekOrder.indexOf(b));

        // ✅ Update departureDays only if needed to prevent unnecessary state updates
        // setAvailabileDayOptions(prev => {
        //     const isValid = prev.every(day => availableDepartureDays.includes(day));
        //     return isValid ? prev : availableDepartureDays;
        // });
        // console.log("sortOption",sortOption);
        
        setAvailabileDayOptions(sortOption)
        
    }, [lengthOfStay]); // ✅ Runs only when filteredDeals change
    
    // Scroll to the first result when filters are applied
    // useEffect(() => {
    //     if (firstResultRef.current) {
    //         firstResultRef.current.scrollIntoView({
    //             behavior: "smooth", 
    //             block: "start"
    //         });
    //     }
    // }, [sortOption, lengthOfStay, departureDays]); // ✅ Trigger scroll when filters change


    const handleRedirectUrl = (urlInfo = "") => {
        if (urlInfo) {
            window.open(urlInfo, "_blank", "noopener, noreferrer");
        }
    };

    const generateBookUrl = (list, urlType) => {
        let baseUrl = "";

        let template = "";

        if (urlType === "select") {
            baseUrl = process.env.REACT_APP_BOOK_URL;
            template =
                "search?gsVendor=CCV&gsPackage=AH01&gsvacationtype=AH01&currentCulture=en-US";
            template +=
                "&gsOrigin=<OriginCode>&gsDestination=<DestinationCode>&gsLengthOfStay=<LengthofStay>&gsDepartureDate=<DepartureDate>";
            template +=
                "&gsReturnDate=<ReturnDate>&gsNumberOfAdults=2&gsNumberOfTravelers=2&gsdateformat=d&gsPrefHotelCode=<HotelCode>";
            template +=
                "&OnSaleTitle=<HotelName>&gssearchlocation=AICCFDI&RedirectToCheckout=True&fopickcheapestflight=true&fopickcharterflight=false&uiShoppingStep=HP";
            return baseUrl + template
                .replace("<OriginCode>", selectedHotelItem.originCode)
                .replace("<DestinationCode>", selectedHotelItem.destinationCode)
                .replace("<LengthofStay>", list.nights)
                .replace("<DepartureDate>", list.departureDateBooking)
                .replace("<ReturnDate>", list.returnDateBooking)
                .replace("<HotelCode>", selectedHotelItem.hotelCode)
                .replace("<HotelName>", selectedHotelItem.hotelName);
        }
    };

    useEffect(() => {
        if(selectedHotelItem!= null){
            setLengthOfStay([selectedHotelItem.nights])
        }
    },[selectedHotelItem])
    

    return (
        <>
            <Dialog
                open={showDeal}
                onClose={(event, reason) => {
                    if (reason === "backdropClick") {
                        return;
                    }
                    handleClose();
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className="show-all-deal-dialog"
            >
                <DialogTitle className="show-all-deals-header" id="alert-dialog-title">
                    <div className="hotel-details">
                        {<img className="hotel-image" src={
                            selectedHotelItem.hotelImage.includes("https") || selectedHotelItem.hotelImage.includes("http")
                                ? selectedHotelItem.hotelImage
                                : `https://${selectedHotelItem.hotelImage}`
                        } alt={selectedHotelItem.hotelName} />}


                        <div className="hotel-content">
                            <div className="hotel-title">{selectedHotelItem.hotelName}</div>
                            <div className="destination-section">
                                {/* <img src={mapIcon} alt="mapIcon" className="icon" /> */}
                                <LocationOnIcon className="icon"/>
                                <div className="title"> {selectedHotelItem.destinationName} </div>
                            </div>
                            <div className="rating-section">
                                {/* <img src={starIcon} alt="mapIcon" className="icon" /> */}
                                <StarsIcon className="icon" />
                                <div className="title">{Math.floor(selectedHotelItem.hotelRating)} star hotel </div>
                            </div>
                        </div>
                    </div>
                    <div className="show-all-deals-title-and-filters">
                        <div className="show-all-deals-title"> Sort and Filter </div>
                        <div >

                            <div className="show-all-deals-filters">
                                {/* Sort By Filter */}
                                <div className="sort-by-filter">
                                    <FormControl className="filter-form" fullWidth>
                                        <InputLabel className="filter-label" id="sort-select-label">
                                            Sort by
                                        </InputLabel>
                                        <Select
                                            labelId="sort-select-label"
                                            id="sort-select"
                                            className="filter-select"
                                            value={sortOption}
                                            onChange={handleSortChange}
                                            MenuProps={{
                                                disablePortal: true, // Ensures the menu remains within the zoomed div
                                                classes: { paper: "sort-filters-dropdown" }, // Custom class for dropdown
                                            }}
                                        >
                                            <MenuItem className="filter-select-menuItem" value="Price Low to High">Price Low to High</MenuItem>
                                            <MenuItem className="filter-select-menuItem" value="Price High to Low">Price High to Low</MenuItem>
                                            <MenuItem className="filter-select-menuItem"  disabled={lengthOfStay.length === 1}  value="Nights Ascending">Nights Ascending</MenuItem>
                                            <MenuItem className="filter-select-menuItem"  disabled={lengthOfStay.length === 1}  value="Nights Descending">Nights Descending</MenuItem>
                                            <MenuItem className="filter-select-menuItem" value="Date">Date</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                                {/* Length of Stay Filter */}
                                <div className="sort-by-filter">
                                    <FormControl className="filter-form" fullWidth>
                                        <InputLabel className="filter-label" id="length-select-label">
                                            Length of stay
                                        </InputLabel>
                                        <Select
                                            labelId="length-select-label"
                                            id="length-select"
                                            className="filter-select"
                                            multiple
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                            label="Any"
                                            InputLabelProps={{ shrink: true }}
                                            value={lengthOfStay.length == 0 ? lengthOfStay : lengthOfStay.sort((a, b) => a - b)} // ✅ Ensure empty selection shows "Any"
                                            onChange={handleLengthChange}
                                            renderValue={(selected) => {
                                                if (selected.length === 0) {
                                                    return "All Nights";
                                                }
                                                return selected.map(n => `${n} nights`).join(", ");
                                            }}
                                            MenuProps={{
                                                disablePortal: true, // Ensures the menu remains within the zoomed div
                                                classes: { paper: "stay-filters-dropdown" }, // Custom class for dropdown
                                            }}
                                        >

                                            {/* Individual Night Options */}
                                            {nightOptions.length > 0 && nightOptions.map((n) => (
                                                <MenuItem className="filter-select-menuItem" key={n} value={n}>
                                                    <Checkbox className="filter-select-Checkbox" checked={lengthOfStay.length === 0 ? false : lengthOfStay.includes(n)} />
                                                    <ListItemText className="ListItemText" primary={`${n} nights`} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>
                                {/* Departure Day Filter */}
                                <div className="sort-by-filter">
                                    <FormControl className="filter-form" fullWidth>
                                        <InputLabel className="filter-label" id="length-select-label">
                                            Depart day
                                        </InputLabel>
                                        <Select
                                            labelId="length-select-label"
                                            id="length-select"
                                            className="filter-select"
                                            multiple
                                            value={departureDays} // Show all if empty
                                            onChange={handleDepartureDayChange}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                            renderValue={(selected) =>
                                                selected.length === 0
                                                    ? "Any"
                                                    : selected.map(n => `${n.substring(0, 3)}`).join(", ")
                                            }
                                            MenuProps={{
                                                disablePortal: true, // Ensures the menu remains within the zoomed div
                                                classes: { paper: "depart-filters-dropdown" }, // Custom class for dropdown
                                         }}
                                        >
                                            {/* Individual Night Options */}
                                            {availabileDayOptions.map((n) => (
                                                <MenuItem className="filter-select-menuItem" key={n} value={n}>
                                                    <Checkbox className="filter-select-Checkbox" checked={departureDays.length === 0 ? false : departureDays.includes(n)} />
                                                    <ListItemText className="ListItemText" primary={`${n}`} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>

                                </div>
                            </div>



                        </div>
                    </div>
                </DialogTitle>

                <DialogContent className="custom-scrollbar" >

                    <DialogContentText id="alert-dialog-description">


                        <div className="show-all-deals-content">
                            <ul className="list-group" ref={firstResultRef} >
                                {filteredDeals.map((deal,index) => (
                                    filteredDeals.length > 0 &&
                                    <li key={deal.pcid + '' +  index} className="list-group-item">
                                        <div className={`deal-item-container ${deal.isBestDeal ? 'is-recommended' : ''}`}>
                                            <div className="deal-item-part-left">
                                                <div className="deal-date-range">
                                                    <div className="date-range-container">
                                                        <div className="date-range">
                                                            <div>{deal.departureDate}</div>
                                                            <div className="px-1 ">-</div>
                                                            <div>{deal.returnDate}</div>
                                                        </div>

                                                    </div>
                                                </div>

                                                <div className="nights-flights">
                                                    <span className="flights-and-hotels-icons">
                                                        <FlightIcon className="icon" />
                                                        <HotelIcon className="icon" />
                                                    </span>
                                                    {deal.nights} Nights  <span className="flights-and-hotels-text">Hotel + Flight</span>
                                                </div>
                                            </div>
                                            <div className="deal-item-part-right">
                                                <div className="price-container">
                                                    <div className="price">
                                                        {deal.isBestDeal && <sup><small>✦₊</small></sup>}
                                                        ${deal.p.toLocaleString()}
                                                    </div>
                                                    <div className="per-person">per person</div>
                                                </div>
                                                <div className="book-btn-container">
                                                    <button onClick={() => {
                                                        handleRedirectUrl(
                                                            generateBookUrl(deal, "select")
                                                        )
                                                    }} className="book-btn">BOOK DEAL</button>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                ))}


                            </ul>
                            <div className="disclaimer">
                                <div className="taxes-fee"> Prices Includes Taxes + Fees </div>
                                <div className="package-price-text"> *Package prices shown are the lowest found within the past 24 hours. Prices and availability subject to change. Additional terms may apply.</div>

                            </div>
                        </div>



                    </DialogContentText>
                </DialogContent>
                <DialogActions className="DialogActions">
                    <Button onClick={handleClose} className="bg-transparent" color="primary">
                        <CloseIcon />
                    </Button>
                </DialogActions>
            </Dialog>


        </>
    )
}

export default ShowAllDealDates