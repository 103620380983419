import React, { createContext, useState, useContext,useEffect } from "react";
import { useSpeechSynthesis } from "react-speech-kit";
// Create Context
const ReadContext = createContext();

// Context Provider Component
export const ReadProvider = ({ children }) => {
  const [isReadEnabled, setIsReadEnabled] = useState(false); // Default: disabled
  const { cancel } = useSpeechSynthesis(); // Access the cancel function
  const [selectedVoice, setSelectedVoice] = useState('Google UK English Female');
  const [isSliderReading, setIsSliderReading] = useState(false);
  const [sliderCurrentIndex, setSliderCurrentIndex] = useState(null);

  useEffect(() => {
    const loadVoices = () => {
      const availableVoices = window.speechSynthesis.getVoices();

      // Set default voice to "Microsoft Zira" if available
      const defaultVoice = availableVoices.find(
        (voice) => voice.name === "Google UK English Female"
      );

      if (defaultVoice) {
        setSelectedVoice(defaultVoice);
      } else {
        console.warn(
          "Microsoft Zira - English (United States) voice not available. Using the first available voice as a fallback."
        );
        setSelectedVoice(availableVoices[0]); // Fallback to the first available voice
      }
    };

    loadVoices();
    window.speechSynthesis.onvoiceschanged = loadVoices; // Handle voice changes in some browsers
  }, []);
  return (
    <ReadContext.Provider value={{ isReadEnabled, setIsReadEnabled, cancel,selectedVoice, sliderCurrentIndex,setSliderCurrentIndex, isSliderReading, setIsSliderReading}}>
      {children}
    </ReadContext.Provider>
  );
};

// Custom Hook for convenience
export const useReadContext = () => useContext(ReadContext);
