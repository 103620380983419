import React, { Suspense, useState, useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { RoutersList } from './RoutersList';
import DycRouteSettingConfig from './DycRouteSettingConfig';
import Loader from '../common/loader/Loader';
import MaintenancePage from '../layout/Maintance';



const ResolveRoutes = ({ isUnderMaintenance }) => {
    if (isUnderMaintenance) {
        // Render Maintenance Page if under maintenance
        return <MaintenancePage />;
    }

    if (RoutersList) {
        return RoutersList.map((routes, i) => {
            return (
                <DycRouteSettingConfig
                    key={i}
                    routeLayout={routes.RouteLayout}
                    protectRoute={routes.ProtectRoute}
                    routeList={routes.RouteList}
                    index={i}
                />
            );
        });
    }
};

const AppRoute = (props) => {
    const [isUnderMaintenance, setIsUnderMaintenance] = useState(false);
    useEffect(() => {
       // Define the maintenance start and end times in CST
            const maintenanceStartCST = new Date("2025-01-17T23:05:00+05:30"); // 10:00 PM IST, January 24th, 2025
            const maintenanceEndCST = new Date("2025-01-17T23:10:00+05:30"); // 11:00 AM IST, January 25th, 2025
            // Convert CST times to UTC (ISO strings)
            const maintenanceStartUTC = maintenanceStartCST.toISOString();
            const maintenanceEndUTC = maintenanceEndCST.toISOString();

            // Get the current time in UTC
            const currentTimeUTC = new Date().toISOString();

            // Function to check if current time is within maintenance window
            function isMaintenanceModeActive(currentTime, startTime, endTime) {
                if (currentTime >= startTime && currentTime <= endTime) {
                    return true;  // Maintenance mode is active
                }
                return false;  // Maintenance mode is not active
            }

            // Check if maintenance mode is active
            const isActive = isMaintenanceModeActive(currentTimeUTC, maintenanceStartUTC, maintenanceEndUTC);
            if (isActive) {
               setIsUnderMaintenance(isActive);
            }
          // Periodically check to update the maintenance status          
        // setIsUnderMaintenance(true);
    }, []); // Runs only once when the component mounts

    return (
        <React.Fragment>
            <Loader isOpen={false} />
            <Suspense fallback={<h1><Loader isOpen={true} /></h1>}>
                <BrowserRouter>
                    {/* Pass maintenance state to ResolveRoutes */}
                    <ResolveRoutes isUnderMaintenance={isUnderMaintenance} />
                </BrowserRouter>
            </Suspense>
        </React.Fragment>
    );
};

export default AppRoute;
