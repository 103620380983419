import React, { useEffect, useState, useMemo } from "react";
import Slider from "react-slick";
import { useSelector } from "react-redux";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Tooltip from "@mui/material/Tooltip";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@material-ui/core/Button";
import axios from "axios";
import { Icon } from "@iconify/react";
import dateHelper from "../../common/helpers/date/dateHelper";
import WeatherIcon from "../../assets/img/weather-icon.png";
import cloudIcon from "../../assets/img/weatherIcon.png";
import favoriteIcon from "../../assets/img/favorite.png";
import PlusIcon from '../../assets/ai-img/task-list-add-icon.png';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import ShowAllDealDates from "./ShowAllDealDates";
import PuffLoader from "react-spinners/PuffLoader";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import loaderImage from '../../assets/img/spinner-loader.gif';  // Add your loader image in the assets folder

const defaultSettings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  initialSlide: 0,
  nextArrow: <Icon icon="ooui:next-ltr" />,
  prevArrow: <Icon icon="ooui:next-rtl" />,
  centerMode: false,
  responsive: [
    {
      breakpoint: 1050,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: false,
        dots: true,
      },
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        infinite: false,
        dots: true,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        lazyLoad: false,
      },
    },
    {
      breakpoint: 576,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1,
      },
    },
  ],
};

const AiShoppingCarousel = (props) => {
  const [modalContent, setModalContent] = useState({
    isOpen: false,
    modelMessage: [],
  });
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [sliderSettings, setSliderSettings] = useState(defaultSettings);
  const [packageList, setPackageList] = useState([]);
  const { responseInfo } = props;
  const [selectedHotelItem,setSelectedHotelItem] = useState(null);
  const [nightOptions,setNightOptions] = useState([]) ; // Available nights
  const days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
  const [daysOptions,setDaysOptions] = useState([])
  const [priceCacheItems,setPriceCacheItems] = useState([])
  const [priceCacheItemsIsLoading,setPriceCacheItemsIsLoading] = useState(false)
  const selector = useSelector((state) => state);

  /////////////////// Show Deal Dates  ////////////////////////////////////
  const [showDeal, setShowDeal] = React.useState(false);

  const showDealOpen = (hotelinfo) => {
    setSelectedHotelItem(hotelinfo);
    fetchHodelCardDeals(hotelinfo)
  };

  const handleClose = () => {
    setSelectedHotelItem(null);
    setPriceCacheItemsIsLoading(false);
    setPriceCacheItems([]);
    setNightOptions([]);
    setDaysOptions([]);
    setShowDeal(false);
  };


  /////////////////// Show Deal Dates  ////////////////////////////////////


  const getReplaceStr = (priceValue = "") => {
    if (priceValue) {
      const replacedStr = Number(priceValue.replace("$", "")).toLocaleString("en-US");
      return replacedStr;
    }
    return "";
  };

  useMemo(() => {
    if (responseInfo?.message?.packages && responseInfo.message.packages.length > 0) {
      const initialPackagesList = responseInfo.message.packages.slice(0, 10);
      setPackageList(initialPackagesList);

      let newSettings = { ...defaultSettings };

      switch (responseInfo.message.packages.length) {
        case 1:
          newSettings.slidesToShow = 3;
          newSettings.dots = false;
          break;
        case 2:
          newSettings.slidesToShow = 3;
          newSettings.dots = false;
          break;
        case 3:
          newSettings.slidesToShow = 4;
          newSettings.dots = false;
          break;
        default:
          newSettings.slidesToShow = 3.4;
          newSettings.slidesToScroll = 3;
          newSettings.dots = true;
      }
      setSliderSettings(newSettings);
    }
  }, [responseInfo.message.packages]);

  const handleRedirectUrl = (urlInfo = "") => {
    if (urlInfo) {
      window.open(urlInfo, "_blank", "noopener, noreferrer");
    }
  };

  const generateBookUrl = (list, urlType) => {
    let baseUrl = "";

    let template = "";
    let lengthofStayDays = dateHelper.getDifferentDays(
      list.departureDate,
      "YYYY-MM-DD",
      list.returnDate,
      "YYYY-MM-DD"
    );
    let departureDate = dateHelper.getDateCurToReqFormat(
      list.departureDate,
      "YYYY-MM-DD",
      "YYYY-MM-DD"
    );
    let returnDate = dateHelper.getDateCurToReqFormat(
      list.returnDate,
      "YYYY-MM-DD",
      "YYYY-MM-DD"
    );

    if (urlType === "select") {
      baseUrl = process.env.REACT_APP_BOOK_URL;
      template =
        "search?gsVendor=CCV&gsPackage=AH01&gsvacationtype=AH01&currentCulture=en-US";
      template +=
        "&gsOrigin=<OriginCode>&gsDestination=<DestinationCode>&gsLengthOfStay=<LengthofStay>&gsDepartureDate=<DepartureDate>";
      template +=
        "&gsReturnDate=<ReturnDate>&gsNumberOfAdults=2&gsNumberOfTravelers=2&gsdateformat=d&gsPrefHotelCode=<HotelCode>";
      template +=
        "&OnSaleTitle=<HotelName>&gssearchlocation=AICCFDI&RedirectToCheckout=True&fopickcheapestflight=true&fopickcharterflight=false&uiShoppingStep=HP";
      return baseUrl + template
        .replace("<OriginCode>", list.originCode)
        .replace("<DestinationCode>", list.destinationCode)
        .replace("<LengthofStay>", list.nights)
        .replace("<DepartureDate>", departureDate)
        .replace("<ReturnDate>", returnDate)
        .replace("<HotelCode>", list.hotelCode)
        .replace("<HotelName>", list.hotelName);
    } else if (urlType === "hoteltitle") {
      baseUrl = process.env.REACT_APP_TITLE_URL;
      let encryptDate = btoa(
        JSON.stringify({ startdate: departureDate, endDate: returnDate })
      );
      template = `hotelinfo/${list.hotelCode}/${list.originCode}/${list.destinationCode}/${list.nights}/` + encryptDate;
      return baseUrl + template;
    } else if (urlType === "showalldeal") {
      baseUrl = process.env.REACT_APP_TITLE_URL;
      let encryptDate = btoa(
        JSON.stringify({ startdate: departureDate, endDate: returnDate, "showDeals": true })
      );
      template = `hotelinfo/${list.hotelCode}/${list.originCode}/${list.destinationCode}/${list.nights}/` + encryptDate;
      return baseUrl + template;
      // baseUrl = process.env.REACT_APP_SHOW_ALL_DEAL_URL;
      // let encryptPayLoadDate = btoa(
      //   JSON.stringify({
      //     searchType: "destination-less",
      //     origin: list.originCode,

      //     originFullName: list.originName,
      //     hotel_code: list.hotelCode,
      //     showDeals: true,
      //     nights: Number(list.nights),
      //     pageType: "",
      //   })
      // );
      // return baseUrl + "destination-less/results?q=" + encryptPayLoadDate;
    } else {
      return "";
    }
  };

  const getTooltip = (list) => {
    const avgTemp = Number((list.temperatureMinimumValue + list.temperatureMaximumValue) / 2).toFixed(1);
    return (
      <div className="pb-2 text-start">
        <div className="WeatherReportSection shadow-lg text-start">
          <div className="d-flex justify-content-between align-items-center">
            <p className="avg-title mb-1 opacity-50">
              Avg Hist{" "}
              {dateHelper.getCheckCurrentDate(
                list.departureDate,
                "YYYY-MM-DD",
                list.returnDate,
                "YYYY-MM-DD"
              ) === true && "*"}
            </p>
            <p style={{ margin: '0' }}>
              <img src={WeatherIcon} alt="Weather Icon" width="24" height="24" style={{ marginRight: '5px' }} />
            </p>
          </div>
          {dateHelper.getCheckCurrentDate(
            list.departureDate,
            "YYYY-MM-DD",
            list.returnDate,
            "YYYY-MM-DD"
          ) === true ? (
            <div className="d-flex justify-content-between  align-items-center">
              <div>
                <p className="mb-0" style={{ fontSize: "8px" }}>
                  <span>Min Temp </span>
                  <span>
                    {cToF(list.temperatureMinimumValue, false)} (
                    {Number(list.temperatureMinimumValue).toFixed(1) + " \xB0C"}
                    ){" "}
                  </span>
                </p>
                <p className="mb-0" style={{ fontSize: "8px" }}>
                  Max Temp
                  <span>
                    {" "}
                    {cToF(list.temperatureMaximumValue, false)} (
                    {Number(list.temperatureMaximumValue).toFixed(1) + " \xB0C"}
                    ){" "}
                  </span>
                </p>
              </div>

              <div>
                <p
                  className="mb-0 fw-bold ms-2"
                  style={{ color: "#25C9D0", fontSize: "13px" }}
                >
                  <span>{cToF(list.precipitationValue, true) + " \xB0F"} </span>
                </p>
              </div>
            </div>
          ) : (
            <div className="d-flex justify-content-between  align-items-center">
              <div>
                <p className="mb-0" style={{ fontSize: "9px" }}>
                  <span>Min Temp </span>
                  <span>
                    {cToF(list.temperatureMinimumValue, false)} (
                    {Number(list.temperatureMinimumValue).toFixed(1) + " \xB0C"}
                    ){" "}
                  </span>
                </p>
                <p className="mb-0" style={{ fontSize: "9px" }}>
                  Max Temp
                  <span>
                    {" "}
                    {cToF(list.temperatureMaximumValue, false)} (
                    {Number(list.temperatureMaximumValue).toFixed(1) + " \xB0C"}
                    ){" "}
                  </span>
                </p>
              </div>
              <div>
                <p
                  className="mb-0 fw-bold ms-2"
                  style={{ color: "#25C9D0", fontSize: "10px" }}
                >
                  <span>{cToF(avgTemp, true) + " \xB0F"}</span>
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  const handleDialogActive = () => {
    setModalContent({ isOpen: false, modelMessage: [] });
  };

  const fetchHodelCardDeals = (selectedHotelItem) => {
    if (!selectedHotelItem) return;

    const { hotelCode, originCode, destinationCode } = selectedHotelItem;

    let data = JSON.stringify({
      "hotelCode": hotelCode,
      "origin": originCode,
      "destination": destinationCode,
      "nights": ["7", "6", "5", "4", "3"]
    });

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: process.env.NODE_ENV === 'production'
        ? 'https://cheapcaribbeanonsaleapi.cheapcaribbean.com/api/pricecache/getshowalldealdates'
        : 'https://cheapcaribbeanonsaleapiqa.cheapcaribbean.com/api/pricecache/getshowalldealdates',
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

    // ✅ Start Loading
    setPriceCacheItemsIsLoading(true);

    axios.request(config)
      .then((response) => {
        const prD = response.data.success === true ? response.data.priceCacheItems : [];
        if (prD.length > 0) {
          const uniqueNights = [...new Set(prD.map(deal => deal.n.toString()))].sort((a, b) => a - b);
          
          // ✅ Extract unique departure days from all `td` arrays
          const uniqueDays = [...new Set(
            prD.flatMap(deal => deal.td.map(trip =>
              new Date(trip.d.substring(0, 4), trip.d.substring(4, 6) - 1, trip.d.substring(6, 8))
                .toLocaleString("en-US", { weekday: "long" })
            ))
          )];

          // ✅ Sort days from Monday to Sunday
          const weekOrder = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
          const sortedDays = uniqueDays.sort((a, b) => weekOrder.indexOf(a) - weekOrder.indexOf(b));

          setNightOptions(uniqueNights);
          setDaysOptions(sortedDays);
          setPriceCacheItems(prD);
          setShowDeal(true);
        }
      })
      .catch((error) => {
        console.error("Error fetching hotel deals:", error);
      })
      .finally(() => {
        // ✅ Stop Loading
        setPriceCacheItemsIsLoading(false);
      });
  };

  const getPopularPlaces = () => (
    <Dialog
      open={modalContent.isOpen}
      onClose={handleDialogActive}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>Popular Places</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          {modalContent.modelMessage.length > 0
            ? modalContent.modelMessage.map((list, index) => (
              <div key={index}>
                <ul className="pplist">
                  <li>
                    <span className="placesIcon"></span>
                    <a target="_blank" rel="noopener noreferrer">
                      {" "}
                      {list.productName}{" "}
                    </a>
                  </li>
                </ul>
              </div>
            ))
            : "There no records found"}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDialogActive}>OK</Button>
      </DialogActions>
    </Dialog>
  );

  const popularPlacesTooltip = (placesList) => (
    <div className="placesListSection shadow-lg text-start">
      <ul className="pplist">
        {placesList.map((list, index) => (
          <li key={"p" + index}>
            <span className="placesIcon"></span>
            <a href="javascript:void(0)" rel="noopener noreferrer">
              {list.productName}{" "}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );

  const cToF = (celsius, isFloor = false) => {
    var cToFahr = (celsius * 9) / 5 + 32;
    return isFloor ? cToFahr.toFixed(1) : cToFahr.toFixed(1) + " \xB0F";
  };

  const onChangePackagesList = () => {
    if (packageList.length === 10) {
      setPackageList(responseInfo.message.packages);
    }
  };

  const handlePlusRedirectUrl = (urlInfo = "") => {
    if (urlInfo) {
      window.open(urlInfo, "_blank", "noopener, noreferrer");
    }
  };

  const generatePlusRedirectUrl = () => {
    const baseUrl = process.env.REACT_APP_PLUS_BUTTON_REDIRECT_URL + "?q=";
    const selectedOriginValue = selector.originData.originSelected;
    if (selectedOriginValue) {
      let encryptPayLoadData = btoa(
        JSON.stringify({
          searchType: "destination-less",
          origin: selectedOriginValue.cityCode,
          originFullName: `${selectedOriginValue.cityName} (${selectedOriginValue.cityCode})`,
          pageType: "",
        })
      );
      return baseUrl + encryptPayLoadData;
    }
    return "";
  };

  const renderCardDesign = (additionalClass = '') => (
    packageList.map((list, index) => (
      <div
        key={list.hotelName + "_" + index}
        className={`AIslider-item ${additionalClass}`}
      >
        <div className="AIresults-card">
          <div className="AImeta">



            <div className="AItripImg">
              <div class="destination-on-img cursor-pointer" onClick={() =>
                handleRedirectUrl(
                  generateBookUrl(list, "hoteltitle")
                )
              }>
                <div> <FmdGoodIcon className="FmdGoodIcon" /></div>
                {list.destinationShortName != undefined && list.destinationShortName != "" ? <div className="font-Roboto">{list?.destinationShortName} </div> : <div className="font-Roboto">{list?.destinationName} </div>}

              </div>
              <img
                src={
                  list.hotelImage.includes("https") || list.hotelImage.includes("http")
                    ? list.hotelImage
                    : `https://${list.hotelImage}`
                }
                about="tripImg"
                className="cursor-pointer"
                onClick={() =>
                  handleRedirectUrl(
                    generateBookUrl(list, "hoteltitle")
                  )
                }
                alt={list.hotelName}
              />
            </div>
          </div>
          <div className="AIdescription">
            <div className="row m-0 position-relative">
              <div className="col-12 p-0">
                <div className="grid-space">
                  <div className="AIdetails AIinfoText" style={{ position: 'relative' }} >
                    <Tooltip title={list.hotelName}
                      enterTouchDelay={0}
                      leaveTouchDelay={5000}
                      placement="bottom"
                      componentsProps={{
                        tooltip: {
                          sx: {
                            bgcolor: "rgba(97, 97, 97, 0.92) !important",
                            color: "white",
                            position: "relative",
                            top: "-15px",
                          }
                        }
                      }}
                      slotProps={{
                        popper: {
                          modifiers: [
                            {
                              name: 'offset',
                              options: {
                                offset: [0, -14],
                              },
                            },
                          ],
                        },
                      }}
                    >
                      <h2
                        className=""
                        title=""
                        onClick={() =>
                          handleRedirectUrl(
                            generateBookUrl(list, "hoteltitle")
                          )
                        }
                      >

                        {list.hotelName}
                      </h2>

                    </Tooltip>


                    <p className="AIrating d-flex align-items-center">
                      <Icon icon="el:star-alt" className="icon" />
                      <span>
                        {Math.floor(list.hotelRating)} star hotel
                      </span>
                    </p>

                    <div className="AIrightDetails">

                      <div className="">
                        <div className="d-flex justify-content-between">
                          <div className="">
                            <p className={`getDifferentDays  ${list.excursions.length === 0 ? 'mt-0' : ''}`}>
                              {/* <span className="d-flex gap-1">
                                <Icon className="sicon" icon="material-symbols:flight" />
                                <Icon className="sicon" icon="material-symbols:hotel-sharp" />
                              </span> */}

                              {dateHelper.getDifferentDays(
                                list.departureDate,
                                "YYYY/MM/DD",
                                list.returnDate,
                                "YYYY/MM/DD"
                              )}{" "}
                              Nights Hotel + Flight

                            </p>
                            <p className="m-0 price-disclmr-note" > Includes Taxes + Fees</p>
                          </div>
                          <div className="">
                            <p className="m-0 from-text ">From </p>
                            <div className="d-flex">
                              <p className="AIprice-h5 me-1 fw-bold ng-star-inserted AIprice-margin-cls">
                                {/* <Icon icon="bx:dollar" className="dollar-icon" /> */}

                                <span className="packagePrice"> ${getReplaceStr(list.packagePrice)}{" "}</span>
                              </p>
                              <p className="AIprice-span per-person-para-cls">per<br /> person</p>

                              {/* <small className="price-disclmr-note">Includes Taxes + Fees</small> */}
                            </div>
                          </div>
                        </div>
                        <div className="book-the-deal">

                          <div className="">
                            <div className="ps-1">
                              <p className="m-0 p-0 book-this-deal">  Book This Deal </p>
                              <p className="AIdate d-flex align-items-center flex-wrap">
                                <Icon
                                  icon="mdi:calendar-range"
                                  className="icon"
                                />
                                <span className="pe-1 d-block">
                                  {dateHelper.getDateCurToReqFormat(
                                    list.departureDate,
                                    "YYYY/MM/DD",
                                    "ddd, MMM DD"
                                  )}
                                </span> - <span className="d-block ps-1">
                                  {dateHelper.getDateCurToReqFormat(
                                    list.returnDate,
                                    "YYYY/MM/DD",
                                    "ddd, MMM DD"
                                  )}
                                </span>
                              </p>
                            </div>
                          </div>
                          <div className="">

                            <button
                              type="button"
                              className="btn AIbook-btn stretched-link text-danger"
                              onClick={() =>
                                handleRedirectUrl(
                                  generateBookUrl(list, "select")
                                )
                              }
                            >
                              {/* <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                <path fill="currentColor" d="M12.6 12L8 7.4L9.4 6l6 6l-6 6L8 16.6z" />
                                </svg> */}
                              <ArrowForwardIosIcon class="icon" />

                            </button>

                          </div>



                        </div>

                      </div>


                      {/* 
                      <Tooltip title={list?.destinationName}
                        enterTouchDelay={0}
                        leaveTouchDelay={5000}
                        placement="bottom-start"
                        componentsProps={{
                          tooltip: {
                            sx: {
                              bgcolor: "rgba(97, 97, 97, 0.92) !important",
                              color: "white"
                            }
                          }
                        }}
                        slotProps={{
                          popper: {
                            modifiers: [
                              {
                                name: 'offset',
                                options: {
                                  offset: [0, -14],
                                },
                              },
                            ],
                          },
                        }}
                      >
                        <p className="AIlocation d-flex align-items-center">
                          <Icon icon="ph:map-pin" className="icon" />
                          <span>{list?.destinationName}</span>
                        </p>
                      </Tooltip> */}




                      <Button
                        variant="outlined"
                        className="btn  AIdealDates"
                        onClick={() => showDealOpen(list)
                          // handleRedirectUrl(
                          //   generateBookUrl(list, "showalldeal")
                          // )
                        }
                      >
                        SHOW ALL DEAL DATES
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
              {/* 
              <div className="col-12 p-0">
                <div className="AIrightDetails">
                  <div className="price-p h-100">

                     <div className="AIweather-places">
                      <Tooltip
                        title={getTooltip(list)}
                        enterTouchDelay={0}
                        leaveTouchDelay={5000}
                        placement="left"
                        className="AIweatherTooltip d-none"
                      >
                        <img
                          className="icons"
                          src={cloudIcon}
                          alt=""
                        />
                      </Tooltip>
                      {list.excursions.length > 0 && (
                        <Tooltip
                          title={popularPlacesTooltip(list.excursions)}
                          enterTouchDelay={0}
                          leaveTouchDelay={5000}
                          placement={isMobile ? 'top-end' : 'right'}
                          className="AIweatherTooltip"
                          componentsProps={{
                            tooltip: {
                              sx: {
                                padding: isMobile ? '0px' : '4px 8px'
                              }
                            }
                          }}
                        >
                          <img
                            className="icons"
                            src={favoriteIcon}
                            alt=""
                          />
                        </Tooltip>
                      )}
                    </div> 



                  </div>
                </div>
              </div> */}

            </div>
          </div>
        </div>
      </div>
    ))
  );

  return (
    <>
      {getPopularPlaces()}
       {/* ✅ Page Loader */}
       <Backdrop open={priceCacheItemsIsLoading}  style={{ 
        zIndex: 1000, 
        backdropFilter: "blur(6px)",  // ✅ Stronger blur effect like in the image
        backgroundColor: "rgba(255, 255, 255, 0.3)", // ✅ Light overlay with some transparency
      }}>
         <img src={loaderImage} alt="Loading..." style={{ width: "400px" }} />
       </Backdrop>
      {/* {priceCacheItemsIsLoading && <PuffLoader />  } */}
      {packageList.length > 0 && (
        <div className="AIchatBg">
          <div className="AItripDetails">
            <Slider {...sliderSettings}>
              {renderCardDesign()}
              <div
                className="AIslider-plus-item d-flex justify-content-center align-items-center"
                key='AIslider-plus-item'
              >
                {responseInfo.message.packages.length > 10 && packageList.length === 10 ? (
                  <div className="plus-btn-card-class">
                    <button className="btn plus-btn-class d-flex flex-column align-items-center" onClick={onChangePackagesList}>
                      <img src={PlusIcon} alt="PlusIcon" className="my-2" />
                      <button className="show-more-packages-btn-class p-1 px-3 my-2">
                        Show more packages
                      </button>
                    </button>
                  </div>
                ) : (
                  <div className="plus-btn-card-class">
                    <button className="btn plus-link-btn d-flex flex-column align-items-center" onClick={() => handlePlusRedirectUrl(generatePlusRedirectUrl())}>
                      <img src={PlusIcon} alt="PlusIcon" className="my-2" />
                      <p className="show-more-packages-paragraph-class my-2 px-3">
                        Tell me more about what you would like to see for more custom packages or visit CheapCaribbean for all vacay options.
                      </p>
                    </button>
                  </div>
                )}
              </div>
            </Slider>
          </div>
        </div>
      )}
      {showDeal && !priceCacheItemsIsLoading && <ShowAllDealDates showDeal={showDeal} selectedHotelItem={selectedHotelItem} selectedNight={selectedHotelItem?.nights} handleClose={handleClose} nightOptions={nightOptions} daysOptions={daysOptions} priceCacheItems={priceCacheItems} />}
    </>
  );
};

export default AiShoppingCarousel;
